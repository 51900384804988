* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul li a, ul li button {
  padding: 0.7rem 1rem;
  text-align: left;
}

.menus {
  position: absolute;
  top: 3.2rem;
  left: 0;
  right: 0;
  
  /*  hide dropdown on small screens  */
  visibility: hidden;
  /*  smooth transitioning  */
  transform: translateY(-1em);
  transition: transform ease 0.2s;
}

/* toggle main dropdown */

input[type="checkbox"]:checked ~ nav > ul {
  visibility: visible;
  transform: translateY(0);
}

.dropdown {
  padding: 2px 1.5rem;
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}

li:focus-within .dropdown {
  height: 135px;
}

.arrow {
  width: 0.5em;
  height: 0.5em;
  display: inline-block;
  vertical-align: middle;
  border-left: 0.15em solid currentColor;
  border-bottom: 0.15em solid currentColor;
  transform: rotate(-45deg);
  margin-left: 0.38em;
  margin-top: -0.25em;
  transition: transform 100ms ease-in-out;
}

li:focus-within > button > .arrow {
  transform: rotate(-225deg);
  margin-top: 4px;
}

/* MEDIA QUERIES  */
@media (min-width: 640px) {
  
  .header-content {
    display: flex;
  }
  
  .menus {
    position: static;
    visibility: visible;
    background: #dc0404;
    display: flex;
    transform: initial;
  }
  
  label, input[type="checkbox"] {
    display: none;
  }
  
  ul li {
    position: relative;
    font-size: 14px;
  }
  
  ul li a:hover,
  ul li button:hover {
    background-color:transparent;
    color: black;
  }
  
  .dropdown {
    position: absolute;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
    z-index: 99;
    min-width: 10rem;
    padding: 0;
    background-color: #fff;
    border-radius: 0 0 0.5rem 0.5rem; 
    color: black;
  }
  
  ul li:hover .dropdown {
    height: 135px;
  }
  
  ul li:hover > button > .arrow {
    transform: rotate(-225deg);
    margin-top: 4px;
  }
}